
  .breadcrumbs {
    text-align: left;
    padding-bottom: 1rem;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    align-content: flex-start;
    gap: 0.8rem;

    & > :nth-child(1) {
      font-weight: bold;
    }

    .arrow-next:last-child {
      display: none;
    }
  }
