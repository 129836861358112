
.loading {
  padding: 0.5em;
  border-radius: 5px !important;
  background: url('~@/assets/img/iconos/loading.gif');
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -10000px;
}

.loading-excel {
  width: var(--icon-size-small);
  height: var(--icon-size-small);
  margin-left: 10px;
  background: url('~@/assets/img/iconos/loading.gif');
  background-size: 120px;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-separator {
  padding: 1rem;
}

.pagination {
  padding: 20px 0;
}

.date_filter {
  background: #a3b84b;
  padding: 8px 16px;
  border: 2px solid #9cb045;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 10px;
    width: 22px;
  }

  &:hover {
    background-color: #72881a;
  }
}

.search-term_input {
  padding: 8px 8px;
  font-size: 1rem;
}

table {
  margin-top: 5px;
}

.small-text {
  text-align: left;
}

.card__body {
  padding: 1.5rem;
  border: 1px solid #cccccc;
  background: #f3f3f3;
}

.small-left {
  text-align: left;
}

small {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 19px;
  color: #333333;
}

.interact--container {
  align-items: flex-end;
}

.form-control:not(:last-child) {
  margin: 0px;
}

// Fixed Counter
.fixed-container-counters {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 999;
  display: grid;
  place-items: center;
  padding-bottom: 0px;
}

.wrapper-counters {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 2rem;
  color: white;
  background-color: #98989d;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  animation: showCounters 200ms forwards ease-in;
}

.form-group {
  text-align: left;
}

.controls-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.template__warning {
  background: rgb(255, 207, 179);
  border: 2px solid rgb(226, 53, 1);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 1rem;
  color: rgb(54, 0, 0);

  p::selection {
    background-color: rgb(226, 53, 1);
    color: black;
  }
}

@keyframes showCounters {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
